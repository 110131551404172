<template>
  <div class="single-answer">
    <h2 :class="['question-title', body.header ? 'with-subtext' : '']">
      {{ body.question }}
    </h2>

    <p
      v-if="body.header"
      class="sub-text group-mb"
    >
      {{ body.header }}
    </p>

    <p
      v-if="body.description"
      class="sub-text group-mb"
    >
      {{ body.description }}
    </p>

    <w-checkbox
      v-for="(list, i) in body.answerlist"
      :id="list.name + body.id"
      :key="list.name + i"
      v-model="answer"
      type="radio"
      :value="list.id"
      :label="list.name"
      :class="list.name == 'No Preference' ? 'no-option' : ''"
      :disabled="disabled"
      :description="list.description"
      :recommended="list.mostpopular || list.bestdeal"
      :recommended-text="
        list.mostpopular ? 'Most Popular' : list.bestdeal ? 'Best Deal' : ''
      "
      :treatment="list.tag == 'treatmentform'"
      :treatment-text="list.pricingstatement"
      :bold="list.tag == 'treatmentform' ? true : false"
      @change="changeAnswer(answer, list)"
    >
      <template
        v-if="list.image"
        #image
      >
        <div class="img-holder">
          <img
            :src="list.image"
            :alt="list.name"
          >
        </div>
      </template>
      <template v-if="list.discountedprice">
        <ul class="list-unstyled mb-0 mt-3">
          <li class="text-muted small-text">
            <s>{{ list.regularprice }}</s>
          </li>
          <li>
            <span class="mb-0 small-text bold-font">
              {{ list.discountedprice }}
            </span>
          </li>
        </ul>
      </template>
      <template
        v-if="
          list.hasOwnProperty('regularprice') &&
            !list.hasOwnProperty('discountedprice')
        "
      >
        <span class="mb-0 mt-3">{{ list.regularprice }}</span>
      </template>
    </w-checkbox>

    <b-button
      v-if="showContinue"
      class="slight-rounded"
      :disabled="disabled"
      pill
      block
      variant="info"
      @click="checkAnswer(answer)"
    >
      Continue
    </b-button>

    <ul
      v-if="body.footerarray"
      class="mt-5"
    >
      <li
        v-for="(item, i) in body.footerarray"
        :key="`fruit-${i}`"
        class="sub-text group-mb mt-3"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
import SingleCheckbox from './SingleCheckbox.vue';

export default {
  components: {
    SingleCheckbox,
  },
  props: {
    body: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      answer: null,
      showContinue: false,
    };
  },
  async created() {
    if (this.body.answer) {
      this.answer = this.body.answer;
      const selectedAnswer = this.body.answerlist.filter(
        answer => answer.id == this.answer
      )[0];
      if (selectedAnswer.inline) {
        this.showContinue = false;
        this.$emit(
          'submitAnswer',
          this.body.id,
          this.answer,
          this.body,
          selectedAnswer
        );
      } else {
        this.showContinue = true;
      }
    } else {
      if (this.body.preventautosubmit) {
        this.showContinue = true;
      }
    }
  },
  methods: {
    changeAnswer(answer, answerBody) {
      if (answerBody.hasOwnProperty('inline')) {
        this.showContinue = false;
      }

      if (!this.showContinue) {
        this.checkAnswer(answer, answerBody);
      }
    },
    checkAnswer(answer, answerBody) {
      if (answer == null) {
        this.$emit('alert', 'Please select an answer below.');
      } else {
        this.$emit(
          'submitAnswer',
          this.body.id,
          this.answer,
          this.body,
          answerBody
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-holder {
  margin-right: -20px;
}
</style>
