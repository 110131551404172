<template>
  <b-row>
    <b-col
      v-if="!nonrx"
      cols="12"
    >
      <h1 class="question-title with-subtext">
        Success! Your visit has been sent to a doctor.
      </h1>
      <p class="sub-text">
        Okay great! Winona Physician is reviewing your completed medical questionnaire,
        and will reply to you shortly.
      </p>

      <b-row
        align-v="center"
        class="mt-5 mb-5"
      >
        <b-col cols="auto">
          <!-- <b-avatar
            :src="require('@/assets/images/onboarding/doctor.png')"
            size="7rem"
          ></b-avatar> -->
          <p class="sub-text">
            What's Next?
            <ul>
              <li>
                You will be paired with a board-certified Winona Physician in your state
              </li>
              <li>
                The Winona Physician will review your medical questionnaire and determine the treatment plan that is the best fit for you
              </li>
              <li>They will reach out to you through the patient portal to address any questions</li>
              <li>
                If they determine the right treatment plan for you, they will prescribe and process your treatment.
              </li>
            </ul>
          </p>
        </b-col>
        <!-- <b-col>
          <h2 class="mb-2 loader-text">Dr. Michael Green11, OB/GYN</h2>
          <p class="mb-0 loader-sub-text">CA License #: G80363</p>
        </b-col> -->
      </b-row>

      <b-button
        size="lg"
        pill
        variant="info"
        class="mb-7 slight-rounded"
        @click="done"
      >
        Go to Patient Dashboard
      </b-button>
      <div class="mb-2"></div>

      <h2 class="label-header mb-3">
        Have a question?
      </h2>
      <p>
        Please go to our
        <a
          :href="`${$store.state.website}/support`"
          class="text-info text-underline"
        >Help Center</a>
        where you get answers to commonly asked questions about billing,
        shipment status, etc.
      </p>
    </b-col>
    <b-col v-else>
      <h1
        class="heading"
        style="line-height: 30px"
      >
        Success! Your payment has been processed and your order is complete.
      </h1>
      <p>You may keep track of your order by following the link below.</p>
      <b-button
        pill
        variant="info"
        @click="done"
      >
        View your Order
      </b-button>
    </b-col>

    <OneClickUpsell
      v-show="isUpsellModalVisible"
      :is-upsell-modal-visible.sync="isUpsellModalVisible"
      upsell-from="Onboarding Upsell"
      :couponid="couponid"
    />
  </b-row>
</template>

<script>
import { io } from 'socket.io-client';
import analyticsMixin from '@/mixins/analytics';
import OneClickUpsell from '@/components/onboarding/checkout/OneClickUpsell';

export default {
  components: {
    OneClickUpsell,
  },
  mixins: [analyticsMixin],
  props: {
    body: Object,
    nonrx: {
      type: Boolean,
      default: false,
    },
    analyticsId: {
      type: String,
      default: '',
    },
    couponid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isUpsellModalVisible: false,
    };
  },
  watch: {
    isUpsellModalVisible() {
      if (!this.isUpsellModalVisible) {
        document.documentElement.classList.remove('tw-overflow-hidden');
      } else {
        document.documentElement.classList.add('tw-overflow-hidden');
      }
    },
  },
  async mounted() {
    window.scrollTo(0,0);

    await this.$store.dispatch('upsell/fetchAvailableDashboardUpsell');

    if (this.body.onboardingflow != 'flowB') {
      this.isUpsellModalVisible = true;
    }

    this.$_analytics_track('Onboarding Screen Viewed', {
      firstname: this.body.firstname,
      lastname: this.body.lastname,
      customerId: this.analyticsId,
      screen: '5.5-A',
      onboardingflow: this.body.onboardingflow,
    });
  },
  methods: {
    done() {
      this.connectToSocket()
      if (this.body.onboardingflow == 'flowB') {
        this.$emit('exit');
        this.$router.push({ name: 'home' });
      } else {
        if (this.nonrx) {
          this.$router.push('/treatment');
        } else {
          this.$router.push({ name: 'home' });
        }
      }
    },
    connectToSocket() {
      if (localStorage.getItem('accessToken')) {
        const socket = io(process.env.VUE_APP_WEBSOCKET_URL, {
          auth: {
            token: localStorage.getItem('accessToken'),
          },
          transports: ['websocket', 'polling'],
        });
        this.$store.dispatch('socket/connectToSocket', socket);
        socket.on('connect', () => {
          console.log('Connected to server');
        });
        socket.on('connect_error', err => {
          console.log(err);
          if (err.message === 'Invalid token') {
            socket.disconnect();
            socket.auth.token = localStorage.getItem('accessToken');
            socket.connect();
            this.$store.dispatch('socket/connectToSocket', socket);
          } else {
            socket.io.opts.transports = ['polling', 'websocket'];
          }
        });
        socket.on('request_error', err => {
          console.log(err);
        });
      }
    },
  },
};
</script>
