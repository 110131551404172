<template>
  <label
    class="
      tw-border-2 tw-border-solid tw-relative
      hover:tw-border-lavender-purple
      tw-py-3 tw-px-5 tw-rounded-3xl
      tw-flex  tw-items-start
      tw-overflow-hidden
      tw-cursor-pointer
    "
    :class="[
      model === item.id ? 'tw-border-lavender-purple' : 'tw-border-silver-sand',
      disabled ? 'tw-opacity-80 tw-pointer-events-none' : ''
    ]"
  >
    <input
      v-model="model"
      type="radio"
      name="foo"
      :value="item.id"
      class="tw-mr-5 tw-flex-shrink-0"
    >

    <div class="tw-flex tw-flex-col tw-gap-2 tw-select-none">
      <span class="tw-font-bold tw-text-base tw-font-atten-bold">{{ item.name }}</span>

      <div
        v-if="item.description"
        class="mb-3"
      >
        <p
          class="tw-font-thin tw-text-sm tw-leading-none tw-m-0"
          :class="{
            'tw-line-clamp-2': !isDescriptionVisible
          }"
        >
          {{ item.description }}
        </p>

        <span
          class="tw-inline-block tw-font-thin tw-text-sm tw-text-ocean-depths tw-underline tw-cursor-pointer"
          @click.prevent="isDescriptionVisible = !isDescriptionVisible"
        >
          {{ isDescriptionVisible ? 'Less' : 'More' }} Details
        </span>
      </div>

      <p
        v-if="item.pricingstatement"
        class="tw-leading-none tw-font-atten-bold tw-m-0"
      >{{ item.pricingstatement }}</p>
    </div>

    <div
      class="tw-w-[50px] tw-flex-shrink-0 tw-bg-cover tw-bg-no-repeat"
      :class="[
        item.image ? 'tw-h-[70px]' : ''
      ]"
      :style="{
        backgroundImage: `url(${item.image})`
      }"
    >
    </div>

    <div
      v-if="item.mostpopular || item.bestdeal"
      class="tw-absolute tw-text-[10px] tw-bg-tangerine-dream tw-text-white tw-py-1 tw-px-3 tw-right-0 tw-uppercase"
    >
      {{
        item.mostpopular
          ? 'Most Popular'
          : item.bestdeal ? 'Best Deal' : ''
      }}
    </div>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDescriptionVisible: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
}
</script>

<style lang="scss">
  input[type="radio"]{
  appearance: none;
  border: 1px solid #d3d3d3;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  content: none;
  outline: none;
  margin: 0;
  background-color: #d3d3d3;
}

input[type="radio"]:checked {
  appearance: none;
  outline: none;
  padding: 0;
  content: none;
  border: none;
}

input[type="radio"]:checked::before{
  position: absolute;
  color: white !important;
  content: "";
  border: 1px solid #a783ff;
  background: #a783ff;
  font-weight: bolder;
  font-size: 10px;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image:url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='white'%3e%3cpath d='M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z'/%3e%3c/svg%3e");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}
</style>